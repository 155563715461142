export function pushArreglo(aux, element, tipo_arreglo){
    
    switch (tipo_arreglo) {
        case "ciudades":
            return aux.push({id: element.id ,nombre: element.nombre, codigo_postal: element.codigo_postal, provincia: element.provincia, pais: element.pais, tildado: false, activo: false})            

        case "anunciantes":
            return aux.push({id:element.id, nombre: element.nombre, correo: element.correo, correo_alt: element.correo_alt,
                telefono: element.telefono ,comentario: element.comentario, domicilio: element.domicilio, ciudad: element.ciudad, activo: false, tildado: false})
        
        case "puntoswifi":
            return aux.push({id:element.id, nombre: element.nombre, domicilio: element.domicilio, ciudad: element.ciudad, anuncio: element.anuncio,
                latitud: element.latitud, longitud: element.longitud ,comentario: element.comentario,precioPorDia: element.precioPorDia, hash: element.hash, activo: false, configuracion: false, tildado: false, ver_anuncios: false})
        
        case "usuarios":
            return aux.push({id: element.id, nombre: element.nombre, correo: element.correo, tildado: false, activo: false})

        case "anuncios":
            return aux.push({id: element.id, nombre: element.nombre, anunciante: element.anunciante, estado: element.estado, duracion: element.duracion, costo_total: element.costo_total, comentario: element.comentario, tildado: false, activo: false, puntowifis: element.puntowifis, imagen: element.imagen, ver_punto: false, ver_imagen: false, full_image: false})

        case "impresiones":
            return aux.push({id: element.id, anuncio: element.anuncio,  punto_wifi: element.punto_wifi, fyh_impresion: element.fyh_impresion, tildado: false})

        default:
            break;
    }

    
}