import {pushArreglo} from './pushArreglo.js'

export function filtrar_campo_numeral(dato, tipo, cod, arreglo, aux, tipo_arreglo){

    if (tipo == 'es') {
        arreglo.forEach(element => {
            switch (cod) {
                case "codigo_postal":
                    if (parseInt(element.codigo_postal) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "telefono":
                    if (parseInt(element.telefono) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "id":
                    if (parseInt(element.id) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "latitud":
                    if (parseInt(element.latitud) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "longitud":
                    if (parseInt(element.longitud) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;
                
                case "precioxdia":
                    if (parseInt(element.precioPorDia) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "costo total":
                    if (parseInt(element.costo_total) == parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        });
    } else if (tipo == 'mayor') {
        arreglo.forEach(element => {
            switch (cod) {
                case "codigo_postal":
                    if (parseInt(element.codigo_postal) > parseInt(dato))
                    pushArreglo(aux, element, tipo_arreglo)
                    break;

                case "telefono":
                    if (parseInt(element.telefono) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "id":
                    if (parseInt(element.id) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "latitud":
                    if (parseInt(element.latitud) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "longitud":
                    if (parseInt(element.longitud) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;
                
                case "precioxdia":
                    if (parseInt(element.precioPorDia) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "costo total":
                    if (parseInt(element.costo_total) > parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    } else if(tipo == 'menor') {
        arreglo.forEach(element => {
            switch (cod) {
                case "codigo_postal":
                    if (parseInt(element.codigo_postal) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "telefono":
                    if (parseInt(element.telefono) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "id":
                    if (parseInt(element.id) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "latitud":
                    if (parseInt(element.latitud) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "longitud":
                    if (parseInt(element.longitud) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "precioxdia":
                    if (parseInt(element.precioPorDia) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "costo total":
                    if (parseInt(element.costo_total) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    } else {
        arreglo.forEach(element => {
            switch (cod) {
                case "codigo_postal":
                    if (parseInt(element.codigo_postal) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "telefono":
                    if (element.telefono == '') {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "id":
                    if (parseInt(element.id) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "latitud":
                    if (parseInt(element.latitud) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "longitud":
                    if (parseInt(element.longitud) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "precioxdia":
                    if (parseInt(element.precioPorDia) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "costo total":
                    if (parseInt(element.costo_total) < parseInt(dato)) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    }
    arreglo = aux
    return arreglo
}