import {pushArreglo} from './pushArreglo.js'

export function filtrar_campo_texto(dato, tipo, cod, arreglo, aux, tipo_arreglo){

    if (tipo == 'contiene' || tipo == 'es') {
        arreglo.forEach(element => {
            switch (cod) {
                case "nombre":
                    if (element.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        // console.log(element)
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "provincia":
                    if (element.provincia.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "pais":
                    if (element.pais.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "domicilio":
                    if (element.domicilio.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "ciudad":
                    if (element.ciudad.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo":
                    if (element.correo.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo_alt":
                    if (element.correo_alt.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "comentario":
                    if (element.comentario.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anunciante":
                    // console.log(element)
                    if (element.anunciante.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "estado":
                    if (element.estado.toLowerCase() == dato.toLowerCase()) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anuncio":
                    if (element.anuncio.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "puntowifi":
                    if (element.punto_wifi.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    } else if (tipo == 'vacio') {
        arreglo.forEach(element => {
            switch (cod) {
                case "nombre":
                    if (element.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "provincia":
                    if (!element.provincia.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "pais":
                    if (!element.pais.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "domicilio":
                    if (element.domicilio == '') {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "ciudad":
                    if (!element.ciudad.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo":
                    if (!element.correo.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo_alt":
                    if (element.correo_alt == '') {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "comentario":
                    if (element.comentario == '') {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anunciante":
                    if (!element.anunciante.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "estado":
                    if (element.estado.toLowerCase() != dato.toLowerCase()) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anuncio":
                    if (!element.anuncio.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "puntowifi":
                    if (!element.punto_wifi.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    } else {
        arreglo.forEach(element => {
            switch (cod) {
                case "nombre":
                    if (!element.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "provincia":
                    if (!element.provincia.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "pais":
                    if (!element.pais.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "domicilio":
                    if (!element.domicilio.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "ciudad":
                    if (!element.ciudad.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo":
                    if (!element.correo.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "correo_alt":
                    if (!element.correo_alt.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "comentario":
                    if (!element.comentario.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anunciante":
                    if (!element.anunciante.nombre.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "estado":
                    if (element.estado.toLowerCase() != dato.toLowerCase()) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "anuncio":
                    if (!element.anuncio.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                case "puntowifi":
                    if (!element.punto_wifi.toLowerCase().includes(dato.toLowerCase())) {
                        pushArreglo(aux, element, tipo_arreglo)
                    }
                    break;

                default:
                    break;
            }
        })
    }
    arreglo = aux
    return arreglo
}