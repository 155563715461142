export function ordenar_arreglo(tipo, dato, arreglo) {
    // console.log(tipo)

    dato = !dato

    switch (tipo) {
        case "nombre":
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
                        return 1
                    }
                    if (a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
               arreglo.sort((a,b) => {
                    if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
                        return 1
                    }
                    if (a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "telefono":
            // Ascendente
            if (dato) {
                arreglo.sort(function(a,b) {
                    return a.telefono - b.telefono
                })
            } else {
                // Descendente
                arreglo.sort(function(a,b) {
                    return b.telefono - a.telefono
                })
            }
            break;

        case "domicilio":
            if (dato) {
                // Ascendiente
                arreglo.sort((a,b) => {
                    if (a.domicilio.toLowerCase() > b.domicilio.toLowerCase()) {
                        return 1
                    }
                    if (a.domicilio.toLowerCase() < b.domicilio.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.domicilio.toLowerCase() < b.domicilio.toLowerCase()) {
                        return 1
                    }
                    if (a.domicilio.toLowerCase() > b.domicilio.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "ciudad":
            // Ascendente
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.ciudad.toLowerCase() > b.ciudad.toLowerCase()) {
                        return 1
                    }
                    if (a.ciudad.toLowerCase() < b.ciudad.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.ciudad.toLowerCase() < b.ciudad.toLowerCase()) {
                        return 1
                    }
                    if (a.ciudad.toLowerCase() > b.ciudad.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "correo":
            // Ascendente
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.correo.toLowerCase() > b.correo.toLowerCase()) {
                        return 1
                    }
                    if (a.correo.toLowerCase() < b.correo.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.correo.toLowerCase() < b.correo.toLowerCase()) {
                        return 1
                    }
                    if (a.correo.toLowerCase() > b.correo.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "correo_alt":
            // Ascendente
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.correo_alt.toLowerCase() > b.correo_alt.toLowerCase()) {
                        return 1
                    }
                    if (a.correo_alt.toLowerCase() < b.correo_alt.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.correo_alt.toLowerCase() < b.correo_alt.toLowerCase()) {
                        return 1
                    }
                    if (a.correo_alt.toLowerCase() > b.correo_alt.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "comentario":
            // Ascendente
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.comentario.toLowerCase() > b.comentario.toLowerCase()) {
                        return 1
                    }
                    if (a.comentario.toLowerCase() < b.comentario.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.comentario.toLowerCase() < b.comentario.toLowerCase()) {
                        return 1
                    }
                    if (a.comentario.toLowerCase() > b.comentario.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "id":
            // Ascendente
            if (dato) {
                arreglo.sort(function(a,b) {
                    return parseInt(a.id) - parseInt(b.id)
                })
            } else {
                // Descendente
                arreglo.sort(function(a,b) {
                    return parseInt(b.id) - parseInt(a.id)
                })
            }
            break;

        case "estado":
            // Ascendente
            if (dato) {
                arreglo.sort((a,b) => {
                    if (a.estado.toLowerCase() > b.estado.toLowerCase()) {
                        return 1
                    }
                    if (a.estado.toLowerCase() < b.estado.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.estado.toLowerCase() < b.estado.toLowerCase()) {
                        return 1
                    }
                    if (a.estado.toLowerCase() > b.estado.toLowerCase()) {
                        return -1
                    }
                    return 0
                })
            }
            break;

        case "costo_total":
            // Ascendente
            if (dato) {
                arreglo.sort(function(a,b) {
                    return a.costo_total - b.costo_total
                })
            } else {
                // Descendente
                arreglo.sort(function(a,b) {
                    return b.costo_total - a.costo_total
                })
            }
            break;

        case "precioxdia":
            // Ascendente
            if (dato) {
                arreglo.sort(function(a,b) {
                    return a.precioPorDia - b.precioPorDia
                })
            } else {
                // Descendente
                arreglo.sort(function(a,b) {
                    return b.precioPorDia - a.precioPorDia
                })
            }
            break;

        case "anunciante":
            if (dato) {
                arreglo.sort((a,b) => {
                    // console.log(a)
                    if (a.anunciante.nombre.toLowerCase() > b.anunciante.nombre.toLowerCase()) {
                        return 1
                    }
                    if (a.anunciante.nombre.toLowerCase() < b.anunciante.nombre.toLowerCase() ) {
                        return -1
                    }
                    return 0
                })
            } else {
                // Descendente
                arreglo.sort((a,b) => {
                    if (a.anunciante.nombre.toLowerCase() < b.anunciante.nombre.toLowerCase()) {
                        return 1
                    }
                    if (a.anunciante.nombre.toLowerCase() > b.anunciante.nombre.toLowerCase() ) {
                        return -1
                    }
                    return 0
                })
            }
            break;
        case "anuncios_totales":
                // Ascendente
            if (dato) {
                arreglo.sort(function(a,b) {
                    return a.anuncio.length - b.anuncio.length
                })
            } else {
                // Descendente
                arreglo.sort(function(a,b) {
                    return b.anuncio.length- a.anuncio.length
                })
            }
            break;


        default:
            break;
    }

    return arreglo

}
